

































































import { Vue, Component } from 'vue-property-decorator';
import { queries, schema, mutations } from '@/gql';
import { Validate } from 'vuelidate-property-decorators';
import { required } from "vuelidate/lib/validators";
import { proxies } from '@/store';
import HeadingCard from '@/components/section/HeadingCard.vue';
import PlaceholderCard from '@/components/section/PlaceholderCard.vue';
import RelativeTimeTooltip from '@/components/tooltip/RelativeTimeTooltip.vue';
import ChipTooltip from '@/components/tooltip/ChipTooltip.vue';

interface Feature {
  feature: string;
  value: string;
}

@Component({
  components: {
    HeadingCard,
    PlaceholderCard,
    RelativeTimeTooltip,
    ChipTooltip,
  },
  apollo: {
    me: {
      query: queries.me,
      fetchPolicy: 'cache-and-network',
      update(query: schema.Query) {
        this.name = query.me.displayName;
        return query.me;
      },
    },
  },
})
export default class UserCard extends Vue {
  private snackbar = proxies.Snackbar;
  private me!: schema.User;
  private dialog = false;
  private submitLoading = false;

  private get loading() {
    return (!this.me && this.$apollo.queries.me.loading) || this.submitLoading;
  }

  @Validate({ required })
  private name = "";
  
  private get nameErrors() {
    const errors: string[] = [];
    if (!this.$v.name.$dirty) return errors;
    if (!this.$v.name.required) errors.push("Name is required.");
    return errors;
  }

  private get featureFlags(): Feature[] {
    if (this.me?.featureFlagsJson) {
      const features = JSON.parse(this.me.featureFlagsJson)
      return Object.entries(features).map(([feature, value]) => {
        return {feature, value} as Feature
      });
    }
    return []
  }

  private async submit() {
    this.$v.$touch();
    if (this.loading || this.$v.$invalid) return;
    this.submitLoading = true;

    try {
      await this.$apollo.mutate<{ user: schema.User }, schema.MutationMeUpdateArgs>({
        mutation: mutations.meUpdate,
        variables: {
          input: {
            displayName: this.name,
          },
        },
      });
      this.snackbar.showSuccess('Name successfully changed!');
      this.dialog = false;
      this.$apollo.queries.me.refresh();
    } catch (err) {
      console.error(err)
      this.snackbar.showWarning('There was an error editing your name');
    } finally {
      this.submitLoading = false;
    }
  }
}
