








import { Vue, Component } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import UserCard from './components/UserCard.vue';
import AuthCard from './components/AuthCard.vue';
import DeleteAccountCard from './components/DeleteAccountCard.vue';

@Component({
  components: {
    SectionContainer,
    UserCard,
    AuthCard,
    DeleteAccountCard,
  },
})
export default class Account extends Vue {

}
