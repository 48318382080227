




















































import { Vue, Component } from 'vue-property-decorator';
import { schema, queries } from '@/gql';
import sourceTypeMdi from '@/common/sourceTypeMdi';

import { mdiCheckCircle } from '@mdi/js'
// import { auth } from '@/plugins/auth';
// import { oauth } from '@/plugins/oauth';
import ButtonTooltip from '@/components/tooltip/ButtonTooltip.vue';
import HeadingCard from '@/components/section/HeadingCard.vue';

@Component({
  components: {
    HeadingCard,
    ButtonTooltip,
  },
  apollo: {
    myUserAuths: {
      fetchPolicy: 'network-only',
      query: queries.myUserAuths,
      update(query: schema.Query) {
        return query.me?.authentication || [];
      },
    },
  },
})
export default class AuthCard extends Vue {
  private myUserAuths: schema.UserAuth[] = [];
  private mdiCheckCircle = mdiCheckCircle;

  private get loading() {
    return !this.myUserAuths && this.$apollo.queries.myUserAuths.loading;
  }

  private get items() {
    return [
      // {
      //   icon: {
      //     color: '',
      //     text: 'mdi-email',
      //   },
      //   text: 'Email / Password',
      //   isConnected: this.myUserAuths.find((i) => i.sourceType === schema.SourceType.Web) !== undefined,
      //   isAuthorizing: false,
      //   authorize: () => {
      //     this.showEmailPasswordDialog = true;
      //   },
      // },
      {
        icon: sourceTypeMdi(schema.SourceType.Discord),
        text: 'Discord',
        isConnected: this.myUserAuths.find((i) => i.sourceType === schema.SourceType.Discord) !== undefined,
        // isAuthorizing: false,
        // authorize: async () => {
        //   this.items[1].isAuthorizing = true;
        //   try {
        //     await oauth.openExternalLoginPopup(schema.SourceType.Discord);
        //     window.location.reload();
        //   } catch (err) {
        //     proxies.Snackbar.showError(`${err}`);
        //   }
        //   this.items[1].isAuthorizing = false;
        // },
      },
      // {
      //   icon: {
      //     color: 'slackPurple',
      //     text: 'mdi-slack',
      //   },
      //   text: 'Slack',
      //   isConnected: this.myUserAuths.find((i) => i.sourceType === schema.SourceType.Slack) !== undefined,
      // },
    ];
  }
}
