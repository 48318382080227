











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ButtonTooltip extends Vue {
  @Prop(String) readonly tooltip!: string;
  @Prop({ default: false }) readonly tooltipDisabled!: boolean;
}
