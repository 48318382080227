



























import { Vue, Component } from 'vue-property-decorator';
import HeadingCard from '@/components/section/HeadingCard.vue';
import { proxies } from '@/store';

@Component({
  components: {
    HeadingCard,
  },
})
export default class DeleteAccountCard extends Vue {
  private snackbar = proxies.Snackbar;
  private user = proxies.User;
  private dialog = false;
  private loading = false;

  private async submit() {
    if (this.loading) return;
    this.loading = true;

    try {
      await this.user.deleteAccount()
      await this.$router.push({ name: 'login' });
      this.dialog = false;
    } catch (err) {
      console.error(err);
      this.snackbar.showError('There was a problem when deleting your account');
    } finally {
      this.loading = false;
    }
  }
}
